<template>
  <Breadcrumb />
  <div class="d-flex justify-content-end">
    <router-link to="New" class="btn btn-success btn-action-new-page"
      ><i class="bi bi-plus"></i>
      {{
        $t(
          "CustomObjects.NewButton",
          {},
          { locale: this.$store.state.activeLang }
        )
      }}</router-link
    >
  </div>
  <Grid
    :settings="gridSettings"
    :columns="gridColumns"
    @onTranslationButtonClick="onTranslationButtonClick"
  />
  <TranslationModal
    :keyTypeId="keyTypeId"
    :parentPublicId="customObjectId"
    :formulaName="formulaName"
    :keyName="this.keyName"
  />
</template>
<script>
import { showModal } from "@/core/helpers/dom";
export default {
  name: "CustomObjectList",
  components: {},
  data() {
    return {
      keyTypeId: 1,
      keyName: "",
      customObjectId: "",
      formulaName: "",
      gridSettings: {
        action: "CustomObjectList",
        requestUrl: String.format("/Lcdp-CustomObjectList"),
        requestUrlRouteParamNames: [],
        routeRequiredParameters: [
          {
            key: "customObjectId",
            value: "publicId",
            isRouteParameter: false,
          },
        ],

        allowSearchPanel: true,
        allowExcelExport: true,
        allowDragAndDrop: false,
        allowPaging: true,
        allowDeleting: false,
        autoOrderProcess: true,

        serialNoLink: "#/CustomObject/Detail/",

        isGatewayRequest: true,

        buttons: [
          {
            name: "businessRuleCache",
            cssClass: "btn-success",
            iconClass: "bi-lightning-fill",
            routeButton: true,
            routeObj: {
              name: "BusinessRuleCacheByCustomObject",
              params: {
                customObjectId: "",
              },
            },
          },
          {
            name: "detail",
            cssClass: "btn-primary",
            iconClass: "bi-arrow-up-right-square",
            routeButton: true,
            routeObj: {
              name: "CustomObjectDetail",
              params: {
                customObjectId: "",
              },
            },
          },
          {
            name: "edit",
            cssClass: "btn-warning",
            iconClass: "bi-pencil-square",
            routeButton: true,
            routeObj: {
              name: "CustomObjectEdit",
              params: {
                customObjectId: "",
              },
            },
          },
        ],
      },
      gridColumns: [
        {
          text: this.$t(
            "CustomObjects.Name",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "name",
          type: "string",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "CustomObjects.FormulaName",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "formulaName",
          type: "string",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "CustomObjects.PluralName",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "pluralName",
          type: "string",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "CustomObjects.FieldCount",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "fieldCount",
          type: "string",
          visible: true,
          width: 200,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.IsActive",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "isActive",
          type: "boolean",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.CreatedBy",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "createdByName",
          type: "string",
          visible: false,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.CreatedAt",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "createdAt",
          type: "datetime",
          visible: false,
          width: 0,
          template: "",
          format: this.$store.getters._dateTimeFormat,
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.UpdatedBy",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "updatedByName",
          type: "string",
          visible: false,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.UpdatedAt",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "updatedAt",
          type: "datetime",
          visible: false,
          width: 0,
          template: "",
          format: this.$store.getters._dateTimeFormat,
          textAlign: "",
        },
      ],
      translateRecordId: null,
      translateFormulaName: null,
    };
  },
  methods: {
    onTranslationButtonClick(rowData) {
      this.customObjectId = rowData.customObjectPublicId;
      this.formulaName = rowData.formulaName;
      this.keyName = rowData.name;
      showModal(document.getElementById("translationModal"));
    },
  },
  mounted() {
    if (this.$store.getters._isMultiLanguage) {
      this.gridSettings.buttons.push({
        name: "translation",
        cssClass: "btn-light translation",
        iconClass: "bi bi-translate",
        routeButton: false,
        emitMethodName: "onTranslationButtonClick",
        iconAttributes: {
          "data-bs-toggle": "tooltip",
          "data-bs-placement": "top",
          title: this.$t(
            "Components.TranslationModal.Title",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      });
    }
  },
};
</script>
